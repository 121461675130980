import {config} from "@/utils"
export default {
    name: 'service',
    components:{
      
       
    },
    mounted () {
    },
    created(){

    },
    methods: {
        changeStat(v){
            this.lNum=v
            if(v==2){
                this.enterbutton="免费查询能否申请"
                this.placeholder='请输入专利关键词，如“多功能台灯”'

            }else if(v==3){
                this.enterbutton="免费查询能否登记"
                this.placeholder='请输入作品名称，如“识权妹”'

            }else{
                this.enterbutton="免费查询能否注册"
                this.placeholder='请输入商标名称，如“识权识美知识产权”'

            }
        },
        changeZ(v){
            this.zNum=v
        },
        changeFoot(v,e){
            if(e !='更多行业'){
                this.fNum=v
                this.delname=e

            }
            
        },
       
        contactKefu(){
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }

        }
    },

    data(){
        return{
            placeholder:'请输入商标名称，如“识权识美知识产权”',
            enterbutton:'免费查询能否注册',
            lNum:1,
            zNum:1,
            fNum:0,
            delname:'餐饮行业',
            work1:[
                {
                    name:"餐饮行业",
                    img:require('./images/hot.png')
                },
                {
                    name:'家居行业',
                    img:require('./images/hot.png')
                },
                {
                    name:'服装行业',
                    img:require('./images/hot.png')
                },
                {
                    name:'旅游行业',
                    img:''
                },
                {
                    name:'建材行业',
                    img:''
                },
                {
                    name:'互联网行业',
                    img:''
                },
                {
                    name:'食品行业',
                    img:require('./images/hot.png')
                },
                {
                    name:'化工行业',
                    img:require('./images/hot.png')
                },
                {
                    name:'摄影行业',
                    img:''
                },
                {
                    name:'电器行业',
                    img:require('./images/hot.png')
                },

                {
                    name:'地产行业',
                    img:''
                },
                {
                    name:'汽车行业',
                    img:''
                },
                {
                    name:'健身行业',
                    img:''
                },
                {
                    name:'乐器行业',
                    img:''
                },
                {
                    name:'医疗行业',
                    img:''
                },
                {
                    name:'母婴行业',
                    img:''
                },
                {
                    name:'宠物行业',
                    img:''
                },
                {
                    name:'传媒行业',
                    img:''
                },
                {
                    name:'化妆品行业',
                    img:require('./images/hot.png')
                },
                {
                    name:'珠宝行业',
                    img:''
                },
                {
                    name:'更多行业',
                    img:''
                },


            ],
            work2:[
                {
                    tit:'07类-厨房器具'
                },
                {
                    tit:'08类-餐具'
                },
                {
                    tit:'16类-餐巾纸'
                },
                {
                    tit:'20类-食品装饰'
                },
                {
                    tit:'21类-炊具器皿'
                },

                {
                    tit:'29类-肉蛋干果'
                },
                {
                    tit:'30类-方便食品'
                },

                {
                    tit:'31类-蔬果生鲜'
                },
                {
                    tit:'32类-啤酒饮料'
                },

                {
                    tit:'33类-酒精饮品'
                },
                {
                    tit:'40类-食品加工'
                },

            ],
            work3:[
                {
                    name:'餐饮行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/a1.png'),
                                    tit:'35类-饭店管理'
                                },
                                {
                                    url:require('./images/category/a2.png'),
                                    tit:'43类-餐厅饭店'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/a3.png'),
                                    tit:'07类-厨房器具'
                                },
                                {
                                    tit:'08类-餐具',
                                    url:require('./images/category/a4.png'),
                                },
                                {
                                    tit:'16类-餐巾纸',
                                    url:require('./images/category/a5.png'),
                                },
                                {
                                    tit:'20类-食品装饰',
                                    url:require('./images/category/a6.png'),
                                },
                                {
                                    tit:'21类-炊具器皿',
                                    url:require('./images/category/a7.png'),
                                },
                
                                {
                                    tit:'29类-肉蛋干果',
                                    url:require('./images/category/a8.png'),
                                },
                                {
                                    tit:'30类-方便食品',
                                    url:require('./images/category/a9.png'),
                                },
                
                                {
                                    tit:'31类-蔬果生鲜',
                                    url:require('./images/category/a10.png'),
                                },
                                {
                                    tit:'32类-啤酒饮料',
                                    url:require('./images/category/a11.png'),
                                },
                
                                {
                                    tit:'33类-酒精饮品',
                                    url:require('./images/category/a12.png'),
                                },
                                {
                                    tit:'40类-食品加工',
                                    url:require('./images/category/a13.png'),
                                },
                
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    url:require('./images/category/a14.png'),
                                    tit:'01类-防腐剂'
                                },
                                {
                                    url:require('./images/category/a15.png'),
                                    tit:'02类-食品色素'
                                },
                                {
                                    url:require('./images/category/a16.png'),
                                    tit:'04类-烧烤用炭'
                                },
                                {
                                    url:require('./images/category/a17.png'),
                                    tit:'12类-餐车'
                                },
                                {
                                    url:require('./images/category/a18.png'),
                                    tit:'24类-餐巾桌布'
                                },
                                {
                                    url:require('./images/category/a19.png'),
                                    tit:'39类-储藏运输'
                                },
                                {
                                    url:require('./images/category/a20.png'),
                                    tit:'41类-厨师培训'
                                },
                                {
                                    url:require('./images/category/a21.png'),
                                    tit:'44类-营养饮食'
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'家居行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/b1.png'),
                                    tit:'07类-家用机械'
                                },
                                {
                                    url:require('./images/category/b2.png'),
                                    tit:'09类-电子电器'
                                },
                                {
                                    url:require('./images/category/b3.png'),
                                    tit:'11类-灯具空调'
                                },
                                {
                                    url:require('./images/category/b4.png'),
                                    tit:'19类-地板门窗'
                                },
                                {
                                    url:require('./images/category/b5.png'),
                                    tit:'20类-家具'
                                },

                                {
                                    url:require('./images/category/b6.png'),
                                    tit:'21类-厨卫用具'
                                },
                                {
                                    url:require('./images/category/b7.png'),
                                    tit:'35类-广告销售'
                                },
                                {
                                    url:require('./images/category/b8.png'),
                                    tit:'42类-装修咨询'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/b9.png'),
                                    tit:'03类-日化用品'
                                },
                                {
                                    tit:'08类-手工机械',
                                    url:require('./images/category/b10.png'),
                                },
                                {
                                    tit:'24类-床上用品',
                                    url:require('./images/category/b11.png'),
                                },
                                {
                                    tit:'27类-地毯地垫',
                                    url:require('./images/category/b12.png'),
                                },
                                {
                                    tit:'37类-室内装修',
                                    url:require('./images/category/b13.png'),
                                },
                
                                {
                                    tit:'42类-装修咨询',
                                    url:require('./images/category/b14.png'),
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    url:require('./images/category/b15.png'),
                                    tit:'06类-日用五金'
                                },
                                {
                                    url:require('./images/category/b16.png'),
                                    tit:'10类-保健器械'
                                },
                                {
                                    url:require('./images/category/b17.png'),
                                    tit:'39类-物流运输'
                                },
                                {
                                    url:require('./images/category/b18.png'),
                                    tit:'40类-材料加工'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'服装行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/c1.png'),
                                    tit:'18类-箱包皮具'
                                },
                                {
                                    url:require('./images/category/c2.png'),
                                    tit:'25类-服装鞋帽'
                                },
                                {
                                    url:require('./images/category/c3.png'),
                                    tit:'35类-广告销售'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/c4.png'),
                                    tit:'05类-卫生内裤'
                                },
                                {
                                    tit:'09类-防护服装',
                                    url:require('./images/category/c5.png'),
                                },
                                {
                                    tit:'10类-矫形服装',
                                    url:require('./images/category/c6.png'),
                                },
                                {
                                    tit:'14类-珠宝服饰',
                                    url:require('./images/category/c7.png'),
                                },
                                {
                                    tit:'22类-纤维原料',
                                    url:require('./images/category/c8.png'),
                                },
                
                                {
                                    tit:'23类-纺织用纱',
                                    url:require('./images/category/c9.png'),
                                },
                                {
                                    tit:'23类-纺织布料',
                                    url:require('./images/category/c10.png'),
                                },
                                {
                                    tit:'26类-服装配饰',
                                    url:require('./images/category/c11.png'),
                                },
                                {
                                    tit:'40类-服装制作',
                                    url:require('./images/category/c12.png'),
                                },
                                {
                                    tit:'42类-服装设计',
                                    url:require('./images/category/c13.png'),
                                },
                                {
                                    tit:'45类-服装出租',
                                    url:require('./images/category/c14.png'),
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    url:require('./images/category/c15.png'),
                                    tit:'16类-时尚杂志'
                                },
                                {
                                    url:require('./images/category/c16.png'),
                                    tit:'37类-洗涤维护'
                                },
                                {
                                    url:require('./images/category/c17.png'),
                                    tit:'38类-服装直播'
                                },
                                {
                                    url:require('./images/category/c18.png'),
                                    tit:'41类-刊物出版'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'旅游行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/d1.png'),
                                    tit:'35类-旅游宣传'
                                },
                                {
                                    url:require('./images/category/d2.png'),
                                    tit:'39类-旅游安排'
                                },
                                {
                                    url:require('./images/category/d3.png'),
                                    tit:'41类-导游服务'
                                },
                                {
                                    url:require('./images/category/d4.png'),
                                    tit:'43类-食宿安排'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/d5.png'),
                                    tit:'06类-金属礼品'
                                },
                                {
                                    tit:'09类-通讯导航',
                                    url:require('./images/category/d6.png'),
                                },
                                {
                                    tit:'14类-珠宝服饰',
                                    url:require('./images/category/d7.png'),
                                },
                                {
                                    tit:'16类-旅游杂志',
                                    url:require('./images/category/d8.png'),
                                },
                                {
                                    tit:'18类-行李箱包',
                                    url:require('./images/category/d9.png'),
                                },
                
                                {
                                    tit:'20类-手工艺品',
                                    url:require('./images/category/d10.png'),
                                },
                                {
                                    tit:'22类-户外帐篷',
                                    url:require('./images/category/d11.png'),
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'09类-通讯导航',
                                    url:require('./images/category/d12.png'),
                                },
                                {
                                    url:require('./images/category/d13.png'),
                                    tit:'28类-户外娱乐'
                                },
                                {
                                    url:require('./images/category/d14.png'),
                                    tit:'25类-旅行服饰'
                                },
                                {
                                    url:require('./images/category/d15.png'),
                                    tit:'45类-安保交友'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'建材行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/e1.png'),
                                    tit:'06类-金属五金'
                                },
                                {
                                    url:require('./images/category/e2.png'),
                                    tit:'17类-橡胶软管'
                                },
                                {
                                    url:require('./images/category/e3.png'),
                                    tit:'19类-建筑材料'
                                },
                                {
                                    url:require('./images/category/e4.png'),
                                    tit:'35类-特许经营'
                                },
                                {
                                    url:require('./images/category/e5.png'),
                                    tit:'37类-建筑装修'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/e6.png'),
                                    tit:'01类-粘合剂'
                                },
                                {
                                    tit:'02类-油漆涂料',
                                    url:require('./images/category/e7.png'),
                                },
                                {
                                    tit:'03类-抛光制剂',
                                    url:require('./images/category/e8.png'),
                                },
                                {
                                    tit:'07类-建筑机械',
                                    url:require('./images/category/e9.png'),
                                },
                                {
                                    tit:'08类-手工工具',
                                    url:require('./images/category/e10.png'),
                                },
                
                                {
                                    tit:'09类-警报装置',
                                    url:require('./images/category/e11.png'),
                                },
                                {
                                    tit:'16类-建筑模型',
                                    url:require('./images/category/e12.png'),
                                },
                                {
                                    tit:'39类-物流配送',
                                    url:require('./images/category/e13.png'),
                                },
                                {
                                    tit:'40类-建材加工',
                                    url:require('./images/category/e14.png'),
                                },
                                {
                                    tit:'42类-建筑设计',
                                    url:require('./images/category/e15.png'),
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'04类-照明燃料',
                                    url:require('./images/category/e16.png'),
                                },
                                {
                                    url:require('./images/category/e17.png'),
                                    tit:'36类-租赁管理'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'互联网行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/f1.png'),
                                    tit:'09类-硬件应用'
                                },
                                {
                                    url:require('./images/category/f2.png'),
                                    tit:'35类-在线推广'
                                },
                                {
                                    url:require('./images/category/f3.png'),
                                    tit:'36类-在线支付'
                                },
                                {
                                    url:require('./images/category/f4.png'),
                                    tit:'38类-在线咨询'
                                },
                                {
                                    url:require('./images/category/f5.png'),
                                    tit:'41类-娱乐服务'
                                },
                                {
                                    url:require('./images/category/f6.png'),
                                    tit:'42类-编程开发'
                                },
                                {
                                    url:require('./images/category/f7.png'),
                                    tit:'45类-社交互动'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/f8.png'),
                                    tit:'07类-智能家用'
                                },
                                {
                                    tit:'11类-厨卫家电',
                                    url:require('./images/category/f9.png'),
                                },
                                {
                                    tit:'28类-游戏器具',
                                    url:require('./images/category/f10.png'),
                                },
                                {
                                    tit:'37类-设备维护',
                                    url:require('./images/category/f11.png'),
                                },
                                
                            ]

                        },
                    ]
                    
                },
                {
                    name:'食品行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/a8.png'),
                                    tit:'29类-肉蛋干果'
                                },
                                {
                                    url:require('./images/category/a9.png'),
                                    tit:'30类-方便速食'
                                },
                                {
                                    url:require('./images/category/a10.png'),
                                    tit:'31类-蔬果生鲜'
                                },
                                {
                                    url:require('./images/category/a11.png'),
                                    tit:'32类-啤酒饮料'
                                },
                                {
                                    url:require('./images/category/a12.png'),
                                    tit:'33类-酒精饮品'
                                },
                                {
                                    url:require('./images/category/b7.png'),
                                    tit:'35类-特许经营'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/a14.png'),
                                    tit:'01类-食品药剂'
                                },
                                {
                                    tit:'02类-食品色素',
                                    url:require('./images/category/a15.png'),
                                },
                                {
                                    tit:'04类-烧烤用炭',
                                    url:require('./images/category/a16.png'),
                                },
                                {
                                    tit:'05类-补药药膳',
                                    url:require('./images/category/g4.png'),
                                },
                                {
                                    tit:'16类-食品包装',
                                    url:require('./images/category/g1.png'),
                                },
                
                                {
                                    tit:'20类-桌椅柜凳',
                                    url:require('./images/category/g2.png'),
                                },
                                {
                                    tit:'21类-炊具器皿',
                                    url:require('./images/category/b6.png'),
                                },
                                {
                                    tit:'34类-香烟制品',
                                    url:require('./images/category/g3.png'),
                                },
                                {
                                    tit:'40类-食品加工',
                                    url:require('./images/category/b18.png'),
                                },
                                {
                                    tit:'43类-餐饮店面',
                                    url:require('./images/category/a2.png'),
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'05类-补药药膳',
                                    url:require('./images/category/g4.png'),
                                },
                                {
                                    url:require('./images/category/b6.png'),
                                    tit:'21类-炊具器皿'
                                },
                                {
                                    url:require('./images/category/b9.png'),
                                    tit:'03类-厨房日化'
                                },
                                {
                                    url:require('./images/category/g5.png'),
                                    tit:'07类-厨房厨具'
                                },
                                {
                                    url:require('./images/category/b17.png'),
                                    tit:'39类-运输仓储'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'化工行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/h1.png'),
                                    tit:'01类-化工原料'
                                },
                                {
                                    url:require('./images/category/h2.png'),
                                    tit:'35类-销售代理'
                                },
                                {
                                    url:require('./images/category/h3.png'),
                                    tit:'40类-化学加工'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/a15.png'),
                                    tit:'02类-颜料树脂'
                                },
                                {
                                    tit:'03类-清洁制剂',
                                    url:require('./images/category/b9.png'),
                                },
                                {
                                    tit:'07类-化工设备',
                                    url:require('./images/category/h4.png'),
                                },
                                {
                                    tit:'08类-研磨器具',
                                    url:require('./images/category/h5.png'),
                                },
                                {
                                    tit:'09类-实验仪器',
                                    url:require('./images/category/c6.png'),
                                },
                
                                {
                                    tit:'17类-纤维材料',
                                    url:require('./images/category/c8.png'),
                                },
                                {
                                    tit:'42类-化学研究',
                                    url:require('./images/category/e8.png'),
                                },
                                
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'14类-贵重金属',
                                    url:require('./images/category/d5.png'),
                                },
                                {
                                    url:require('./images/category/h6.png'),
                                    tit:'16类-化工杂志'
                                },
                                {
                                    url:require('./images/category/f4.png'),
                                    tit:'41类-化工研讨'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'摄影行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/i1.png'),
                                    tit:'16类-相册海报'
                                },
                                {
                                    url:require('./images/category/i2.png'),
                                    tit:'35类-加盟连锁'
                                },
                                {
                                    url:require('./images/category/i3.png'),
                                    tit:'40类-影像加工'
                                },
                                {
                                    url:require('./images/category/i4.png'),
                                    tit:'41类-摄影制作'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/e8.png'),
                                    tit:'01类-化学用品'
                                },
                                {
                                    tit:'09类-摄影器械',
                                    url:require('./images/category/i5.png'),
                                },
                                {
                                    tit:'20类-画框展台',
                                    url:require('./images/category/i6.png'),
                                },
                                {
                                    tit:'38类-自媒体',
                                    url:require('./images/category/i7.png'),
                                },
                                {
                                    tit:'42类-在线设计',
                                    url:require('./images/category/i8.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'25类-摄影服饰',
                                    url:require('./images/category/d14.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'电器行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/j1.png'),
                                    tit:'07类-电子机械'
                                },
                                {
                                    url:require('./images/category/j2.png'),
                                    tit:'09类-电子产品'
                                },
                                {
                                    url:require('./images/category/a7.png'),
                                    tit:'11类-厨卫电器'
                                },
                                {
                                    url:require('./images/category/b7.png'),
                                    tit:'35类-广告销售'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/j3.png'),
                                    tit:'37类-电器维修'
                                },
                                {
                                    tit:'38类-播放设备',
                                    url:require('./images/category/j4.png'),
                                },
                                {
                                    tit:'40类-产品加工',
                                    url:require('./images/category/b18.png'),
                                },
                                {
                                    tit:'41类-产品培训',
                                    url:require('./images/category/j5.png'),
                                },
                                {
                                    tit:'42类-产品系统',
                                    url:require('./images/category/j6.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'06类-日用金属',
                                    url:require('./images/category/d5.png'),
                                },
                                {
                                    tit:'21类-厨房洁具',
                                    url:require('./images/category/j7.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'地产行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/k1.png'),
                                    tit:'35类-地产宣传'
                                },
                                {
                                    url:require('./images/category/k2.png'),
                                    tit:'36类-房产销售'
                                },
                                {
                                    url:require('./images/category/k3.png'),
                                    tit:'37类-建筑建造'
                                },
                                {
                                    url:require('./images/category/e5.png'),
                                    tit:'42类-室内装修'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/a14.png'),
                                    tit:'01类-粘合剂'
                                },
                                {
                                    tit:'02类-油漆涂料',
                                    url:require('./images/category/a15.png'),
                                },
                                {
                                    tit:'06类-金属材料',
                                    url:require('./images/category/k4.png'),
                                },
                                {
                                    tit:'19类-建筑材料',
                                    url:require('./images/category/e3.png'),
                                },
                                {
                                    tit:'40类-材料加工',
                                    url:require('./images/category/e14.png'),
                                },
                                {
                                    tit:'41类-置业顾问',
                                    url:require('./images/category/k5.png'),
                                },
                                {
                                    tit:'44类-园艺服务',
                                    url:require('./images/category/k6.png'),
                                },
                                {
                                    tit:'45类-物管社区',
                                    url:require('./images/category/k7.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'16类-地产杂志',
                                    url:require('./images/category/k8.png'),
                                },
                                {
                                    tit:'17类-隔热材料',
                                    url:require('./images/category/c10.png'),
                                },
                                {
                                    tit:'20类-家居家具',
                                    url:require('./images/category/k9.png'),
                                },
                                {
                                    tit:'21类-厨房洁具',
                                    url:require('./images/category/j7.png'),
                                },
                                {
                                    tit:'27类-地毯墙纸',
                                    url:require('./images/category/k10.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'汽车行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/l1.png'),
                                    tit:'07类-动力设备'
                                },
                                {
                                    url:require('./images/category/d6.png'),
                                    tit:'09类-通讯导航'
                                },
                                {
                                    url:require('./images/category/a19.png'),
                                    tit:'12类-交通运输'
                                },
                                {
                                    url:require('./images/category/a17.png'),
                                    tit:'35类-汽车销售'
                                },
                                {
                                    url:require('./images/category/j3.png'),
                                    tit:'37类-保养维修'
                                },
                                
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/l2.png'),
                                    tit:'04类-汽车燃料'
                                },
                                {
                                    tit:'11类-车用空调',
                                    url:require('./images/category/l3.png'),
                                },
                                {
                                    tit:'39类-运输租车',
                                    url:require('./images/category/l4.png'),
                                },
                                {
                                    tit:'42类-车载软件',
                                    url:require('./images/category/f6.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'22类-汽车拖缆',
                                    url:require('./images/category/l5.png'),
                                },
                                {
                                    tit:'27类-车用脚垫',
                                    url:require('./images/category/l6.png'),
                                },
                                {
                                    tit:'28类-汽车模型',
                                    url:require('./images/category/l7.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'健身行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/b16.png'),
                                    tit:'28类-健身器械'
                                },
                                {
                                    url:require('./images/category/f2.png'),
                                    tit:'35类-广告宣传'
                                },
                                {
                                    url:require('./images/category/d13.png'),
                                    tit:'41类-健身训练'
                                },
                                {
                                    url:require('./images/category/m1.png'),
                                    tit:'44类-营养指导'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/c6.png'),
                                    tit:'10类-矫形器具'
                                },
                                {
                                    tit:'25类-运动服装',
                                    url:require('./images/category/c12.png'),
                                },
                                {
                                    tit:'27类-健身垫子',
                                    url:require('./images/category/b12.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'45类-安保服务',
                                    url:require('./images/category/m2.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'乐器行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/n1.png'),
                                    tit:'15类-声乐器具'
                                },
                                {
                                    url:require('./images/category/n2.png'),
                                    tit:'35类-在线招生'
                                },
                                {
                                    url:require('./images/category/n3.png'),
                                    tit:'41类-音乐培训'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/n4.png'),
                                    tit:'09类-电子乐谱'
                                },
                                {
                                    tit:'16类-活页乐普',
                                    url:require('./images/category/n5.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'38类-声乐广播',
                                    url:require('./images/category/n6.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'医疗行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/g4.png'),
                                    tit:'05类-医药用品'
                                },
                                {
                                    url:require('./images/category/c6.png'),
                                    tit:'10类-医疗器械'
                                },
                                {
                                    url:require('./images/category/h2.png'),
                                    tit:'35类-销售服务'
                                },
                                {
                                    url:require('./images/category/e6.png'),
                                    tit:'42类-医药研究'
                                },
                                {
                                    url:require('./images/category/o1.png'),
                                    tit:'44类-医疗服务'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/j1.png'),
                                    tit:'07类-制药机械'
                                },
                                {
                                    tit:'11类-消毒设备',
                                    url:require('./images/category/o2.png'),
                                },
                                {
                                    tit:'12类-救护车',
                                    url:require('./images/category/o3.png'),
                                },
                                {
                                    tit:'18类-医用拐杖',
                                    url:require('./images/category/o4.png'),
                                },
                                {
                                    tit:'20类-磁疗枕',
                                    url:require('./images/category/o5.png'),
                                },
                                {
                                    tit:'25类-医疗用衣',
                                    url:require('./images/category/o6.png'),
                                },
                                {
                                    tit:'30类-保健食品',
                                    url:require('./images/category/a9.png'),
                                },
                                {
                                    tit:'40类-药材加工',
                                    url:require('./images/category/o7.png'),
                                },
                                {
                                    tit:'45类-会员管理',
                                    url:require('./images/category/o8.png'),
                                },

                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'01类-医用制剂',
                                    url:require('./images/category/e8.png'),
                                },
                                {
                                    tit:'16类-医疗用纸',
                                    url:require('./images/category/o9.png'),
                                },
                                {
                                    tit:'37类-器械维护',
                                    url:require('./images/category/e9.png'),
                                },
                                {
                                    tit:'41类-健身培训',
                                    url:require('./images/category/d13.png'),
                                },
                                {
                                    tit:'43类-养老院',
                                    url:require('./images/category/o10.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'母婴行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/b9.png'),
                                    tit:'03类-母婴日化'
                                },
                                {
                                    url:require('./images/category/p1.png'),
                                    tit:'05类-奶粉尿布'
                                },
                                {
                                    url:require('./images/category/p2.png'),
                                    tit:'10类-奶嘴奶瓶'
                                },
                                {
                                    url:require('./images/category/p3.png'),
                                    tit:'20类-婴儿床枕'
                                },
                                {
                                    url:require('./images/category/p4.png'),
                                    tit:'25类-婴儿衣裤'
                                },
                                {
                                    url:require('./images/category/e4.png'),
                                    tit:'35类-门面招牌'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/p5.png'),
                                    tit:'12类-婴儿车'
                                },
                                {
                                    tit:'21类-婴儿浴盆',
                                    url:require('./images/category/p6.png'),
                                },
                                {
                                    tit:'28类-儿童玩具',
                                    url:require('./images/category/p7.png'),
                                },
                                {
                                    tit:'41类-幼儿辅导',
                                    url:require('./images/category/p8.png'),
                                },
                                {
                                    tit:'44类-婴儿护理',
                                    url:require('./images/category/p9.png'),
                                },
                                {
                                    tit:'45类-家政服务',
                                    url:require('./images/category/p10.png'),
                                },
                                
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'08类-儿童刀叉',
                                    url:require('./images/category/p11.png'),
                                },
                                {
                                    tit:'09类-婴儿秤',
                                    url:require('./images/category/p12.png'),
                                },
                                {
                                    tit:'18类-婴儿抱袋',
                                    url:require('./images/category/p13.png'),
                                },
                                {
                                    tit:'24类-婴儿被罩',
                                    url:require('./images/category/a18.png'),
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'宠物行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/q1.png'),
                                    tit:'31类-宠物食品'
                                },
                                {
                                    url:require('./images/category/c3.png'),
                                    tit:'35类-广告销售'
                                },
                                {
                                    url:require('./images/category/q2.png'),
                                    tit:'43类-宠物寄养'
                                },
                                {
                                    url:require('./images/category/q3.png'),
                                    tit:'44类-宠物美容'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/q4.png'),
                                    tit:'03类-宠物洗浴'
                                },
                                {
                                    tit:'05类-宠物医药',
                                    url:require('./images/category/g4.png'),
                                },
                                {
                                    tit:'12类-宠物座椅',
                                    url:require('./images/category/b12.png'),
                                },
                                {
                                    tit:'18类-宠物用具',
                                    url:require('./images/category/q5.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'20类-宠物床窝',
                                    url:require('./images/category/q6.png'),
                                },
                                {
                                    tit:'28类-宠物玩具',
                                    url:require('./images/category/q7.png'),
                                },
                                {
                                    tit:'45类-宠物火化',
                                    url:require('./images/category/q8.png'),
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'传媒行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/f6.png'),
                                    tit:'09类-软件程序'
                                },
                                {
                                    url:require('./images/category/r1.png'),
                                    tit:'16类-报刊杂志'
                                },
                                {
                                    url:require('./images/category/c3.png'),
                                    tit:'35类-广告推广'
                                },
                                {
                                    url:require('./images/category/r2.png'),
                                    tit:'38类-自媒体'
                                },
                                {
                                    url:require('./images/category/r3.png'),
                                    tit:'41类-文娱服务'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/r4.png'),
                                    tit:'42类-官方网站'
                                },
                               
                            ]
                        },
                    ]
                    
                },
                {
                    name:'化妆品行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/b9.png'),
                                    tit:'03类-日化美妆'
                                },
                                {
                                    url:require('./images/category/s1.png'),
                                    tit:'21类-化妆用具'
                                },
                                {
                                    url:require('./images/category/c3.png'),
                                    tit:'35类-广告宣传'
                                },
                                {
                                    url:require('./images/category/e6.png'),
                                    tit:'42类-化妆品研究'
                                },
                                {
                                    url:require('./images/category/s2.png'),
                                    tit:'44类-化妆美容'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/e8.png'),
                                    tit:'01类-化妆制剂'
                                },
                                {
                                    tit:'05类-含药产品',
                                    url:require('./images/category/s3.png'),
                                },
                                {
                                    tit:'07类-生产设备',
                                    url:require('./images/category/h4.png'),
                                },
                                {
                                    tit:'16类-外观包装',
                                    url:require('./images/category/g1.png'),
                                },
                                {
                                    tit:'20类-化妆家具',
                                    url:require('./images/category/s4.png'),
                                },
                                {
                                    tit:'41类-美容培训',
                                    url:require('./images/category/s5.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'09类-美妆软件',
                                    url:require('./images/category/s6.png'),
                                },
                                {
                                    tit:'18类-化妆包袋',
                                    url:require('./images/category/s7.png'),
                                },
                                {
                                    tit:'25类-美容衣物',
                                    url:require('./images/category/c14.png'),
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'珠宝行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/category/c7.png'),
                                    tit:'14类-珠宝首饰'
                                },
                                {
                                    url:require('./images/category/t1.png'),
                                    tit:'35类-连锁加盟'
                                },
                                {
                                    url:require('./images/category/t2.png'),
                                    tit:'36类-珠宝估价'
                                },
                                {
                                    url:require('./images/category/t3.png'),
                                    tit:'37类-首饰保养'
                                },
                                {
                                    url:require('./images/category/t4.png'),
                                    tit:'42类-首饰设计'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    url:require('./images/category/k4.png'),
                                    tit:'06类-金属产品'
                                },
                                {
                                    tit:'40类-珠宝加工',
                                    url:require('./images/category/e9.png'),
                                },
                                {
                                    tit:'45类-首饰出租',
                                    url:require('./images/category/c7.png'),
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'19类-石制塑像',
                                    url:require('./images/category/t5.png'),
                                },
                                {
                                    tit:'20类-珍珠贝类',
                                    url:require('./images/category/t6.png'),
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'更多行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    url:require('./images/trademark/q2.png'),
                                    tit:'09类-科学仪器'
                                },
                               
                            ]

                        },

                    ]
                    
                },
            ]
            


        }
        
    }
    
}